import APIUrl from "../APIUrl";
import axios from 'axios';
const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export const emailExists = function(email, callback){
    return axios.post(APIUrl.checkEmail, { "email": email })
        .then(function(response){
            if(callback) callback(response.data.message);
        })
        .catch(function(err){
            throw err;
        });
};

export const phoneExists = function(phone, callback){
    return axios.post(APIUrl.checkPhone, { "phone": phone })
        .then(function(response){
            if(callback) callback(response.data.message);
        })
        .catch(function(err){
            throw err;
        });
};

export const checkPassword = function(password, callback){
    return axios.post(APIUrl.checkPassword, { "password": password })
        .then(function(response){
            if(callback) callback(response.data.message);
        })
        .catch(function(err){
            throw err;
        });
}

export function searchDoctorsBySector(filters) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/user/doctor/by-sector";

        return axios.post(url, filters)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "SEARCH_DOCTOR_BY_SECTOR_REQUEST", isGettingDoctors: true}}
	function success(result) { return { type: "SEARCH_DOCTOR_BY_SECTOR_SUCCESS", doctorsBySector: result, isGettingDoctors: false}}
	function failure() { return { type: "SEARCH_DOCTOR_BY_SECTOR_FAILURE", isGettingDoctors: false}}
};


export function searchSubstitutesBySector() {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/user/substitute/by-sector";

        return axios.post(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "SEARCH_SUBSTITUTE_BY_SECTOR_REQUEST", isGettingSub: true}}
	function success(result) { return { type: "SEARCH_SUBSTITUTE_BY_SECTOR_SUCCESS", subBySector: result, isGettingSub: false}}
	function failure() { return { type: "SEARCH_SUBSTITUTE_BY_SECTOR_FAILURE", isGettingSub: false}}
};