import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, TextInput, EmailInput, PhoneInput, PasswordInput, RadioInput, MinLength, NotEmpty } from '@gferrand/react-forms';
import { checkPassword, emailExists } from '../../../actions/user/user';
import Util from '../../../util/Util';
import ImagePicker from "../../../components/image-picker/ImagePicker";
import ProfileImages from "../../../enum/ProfileImages";
import TextDateInput from '../../../components/form/TextDateInput';
import DateUtil from '../../../util/DateUtil';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

/**
 * @param {boolean} hasOfficeNumber Display office phone number.
 * @param {boolean} showPassword Display a button to show the password only during modification or registration.
 * @param {String} role 
 */
class UserForm extends Component {

	constructor(props) {
		super(props);

		const { submitCbk, defaultValues, validate, disableEmail } = this.props;

		if (defaultValues && defaultValues.password) {
			defaultValues.confirmation_mot_de_passe = defaultValues.password;
		}

		this.state = {
			image: 0,
			ispasswordVisible : true,
		};

		const changeCbk = async (input) => {
			if (input.name === "gender" && !ProfileImages.isCorrectGender(this.state.image, input.value)) {
				this.setState({ image: ProfileImages.defaultForGender(input.value) }, () => {
					if (this.openAvatarPicker) this.openAvatarPicker();
				});
			};

			if (input.name === "email") {
				const callback = (msg) => {
					if (msg.emailExists && msg.emailExists !== "temporary") input.setError("Email.Already.Exists");
				};

				this.props.onEmailExists(input.value, callback);
			}

			const formData = this.form.getRawData();

			if (!Util.emptyString(formData.password) && !Util.emptyString(formData.confirmation_mot_de_passe) && formData.password !== formData.confirmation_mot_de_passe) {
				this.form.setError("confirmation_mot_de_passe", "Passwords.Not.Identical");
			}

			if (!Util.emptyString(formData.confirmation_mot_de_passe) && !Util.emptyString(formData.password) && formData.confirmation_mot_de_passe === formData.password) {
				this.form.setError("confirmation_mot_de_passe", null);
			}

			if (input.name === "birth_date") {
				const date = new Date();
				const dateInput = new Date(formData.birth_date);
				if ((dateInput.getFullYear() > date.getFullYear()) || dateInput.getFullYear() < 1900  || DateUtil.age(formData.birth_date) < 18) {
					this.form.setError("birth_date", "Veuillez saisir une date valide");
				}
			}

			if (input.name === "password") {
				const passwordRegex = /(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{}|;:,.<>?\\=\-§/µ])/;
				if (formData.password && formData.password.length < 12) {
					this.form.setError("password", "Le mot de passe doit faire 12 caractères minimum");
				}
				else if (!passwordRegex.test(formData.password)) {
					this.form.setError("password", "Le mot de passe doit contenir au moins un chiffre et un caractère spécial");
				}
				else {
					await checkPassword(formData.password, (msg) => {
						if (msg.result) {
							input.setError("Mot de passe compromis ou trop commun");
						}
					});
				} 
			}


			if (input.name === 'phone_mobile') {
				let regexPhone = new RegExp('^((06)|(07))');
				if (input.value && input.value.length > 1 && !regexPhone.test(input.value)) {
					this.form.setError("phone_mobile", "Error.Start.Number.Phone.Mobile");
				}
			}
		};

		this.form = new Form({
			name: "user",
			inputs: [
				new TextInput("name", "Name", undefined, [MinLength(2), NotEmpty]),
				new TextInput("first_name", "First.Name", undefined, [MinLength(2), NotEmpty]),
				new TextDateInput("birth_date", "DateOfBirth", [NotEmpty], { placeholder: "jj/mm/aaaa" }),
				new RadioInput("gender", "Gender", [{ label: "Gender.Woman", value: "woman" }, { label: "Gender.Man", value: "man" }], [NotEmpty],
					{ groupClassName: " ", className: "srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0" }),
				new EmailInput("email", "Email", 100, [NotEmpty], { disabled: disableEmail }),
				new PasswordInput("password", "Password", 64, [MinLength(12), NotEmpty]),
				new PasswordInput("confirmation_mot_de_passe", "Confirmation.Password", 64, [MinLength(12), NotEmpty]),
				new PhoneInput("phone_mobile", "Phone.Mobile", 10, [MinLength(10), NotEmpty]),
				new PhoneInput("phone_pro", "Office.Phone", 10, [MinLength(10)]),
			],
			submitCbk: (data, cbk) => {
				// Add image!
				data.image = this.state.image;
				data.role = this.props.role;
				submitCbk(data, cbk);
			},
			changeCbk: changeCbk,
			data: defaultValues,
			options: { validate: Util.null(validate) ? true : validate, validateAtStart: false },
		});

		this.openAvatarPicker = null;
	}

	componentDidMount() {
		const { defaultValues } = this.props;
		this.setProfilePicture(defaultValues);
	}

	componentDidUpdate(prevProps) {
		const { defaultValues } = this.props;
		if (prevProps.defaultValues !== defaultValues) { // Fill form step with store data
			this.form.inputs.forEach((elem) => {
				this.form.setValue(elem.name, defaultValues[elem.name]);
			});
			this.setProfilePicture(defaultValues);
		}
	}
	
	togglePasswordVisibility = () => {
		let passwordComponent = document.getElementById("password");
		let passwordConfirmationComponent = document.getElementById("confirmation_mot_de_passe");
		const newType = this.state.ispasswordVisible ? "text" : "password";
		passwordComponent.type = newType;
		passwordConfirmationComponent.type = newType;
		
		this.setState(() => ({
		  ispasswordVisible: !this.state.ispasswordVisible,
		}));
		
	};

	copyPwd = () => {
		let passwordComponent = document.getElementById("password");
		new Noty({
			type: "info",
			layout: 'topRight',
			theme: 'bootstrap-v4',
			text: 'Mot de passe copié dans le presse papier',
			timeout: 6000,
		}).show();
		navigator.clipboard.writeText(passwordComponent.value);
	};

	setProfilePicture(defaultValues) {
		let image = 0;
		if (defaultValues) {
			if (defaultValues.image) image = defaultValues.image;
			else if (defaultValues.gender) image = ProfileImages.defaultForGender(defaultValues.gender);
		}
		this.setState({ image });
	}

	validate() {
		let isValid = true;
		let isFormValid = true;
		this.form.inputs.forEach((elem) => {
			isValid = elem.valid();
			if (isValid === false) {
				isFormValid = false;
			}
		})
		return isFormValid;
	}

	row(form, inputName) {
		return (
			<Row className="form-group">
				<Col md="5">{form.getLabel(inputName)}</Col>
				<Col md="7">{form.getInput(inputName)}</Col>
			</Row>
		);
	}

	validateToScrollTop() {
		window.scrollTo(0, 0);
	}


	render() {
		let { submitButtoni18nId, defaultValues } = this.props;
		
		let rawData = this.form.getRawData();
		if (!defaultValues) defaultValues = {};
		const imgGender = (rawData.gender || defaultValues.gender || "man");
		return (
			<React.Fragment>

				<Row className="step step1 mb-4 justify-content-md-center ">
					<Col>
						<Card className="rounded-0">
							<CardBody className="p-5">

								{this.row(this.form, "name")}
								{this.row(this.form, "first_name")}
								{this.row(this.form, "birth_date")}
								{this.row(this.form, "gender")}

								{this.state.image !== 0 &&
									<Row className="text-center justify-content-md-center mb-4 mx-auto">
										<div className="text-center">
											<ImagePicker
												defaultValue={this.state.image}
												images={((imgGender === "woman") ? ProfileImages.getWomenImgs() : ProfileImages.getMenImgs())}
												onPick={(image) => this.setState({ image })}
												setOpenCbk={(cbk) => this.openAvatarPicker = cbk}
											/>
										</div>
									</Row>
								}

								{this.row(this.form, "email")}

								<Row className="form-group mb-0 pb-0">
									<Col md="5">
										{this.form.getLabel("password")}
									</Col>
									<Col md="5">
										<div style={{display: "flex", width:"100%"}}>
											{this.form.getInput("password")}
										</div>
									</Col>
									<Col md="2">
										<button 
											onClick={this.togglePasswordVisibility.bind(this)} 
											className='text-center'
											style={{right :"-7.5%", margin:'0', padding:"1%", paddingTop:'1.8%', marginTop:"2px",width: "30px", height: "30px"}}>
											{this.state.ispasswordVisible ?  <i class="fa fa-eye" /> : <i class="fa fa-eye-slash" />}
										</button>
										<button 
											onClick={this.copyPwd.bind(this)} 
											className='text-center ml-1 ml-right'
											style={{right :"-7.5%", margin:'0', padding:"1%", paddingTop:'1.8%', marginTop:"2px",width: "30px", height: "30px"}}>
											<i class="fa fa-clipboard" />
										</button>
									</Col>
								</Row>

								<Row className="form-group mb-0 pb-0">
									<Col md="5">
										{this.form.getLabel("confirmation_mot_de_passe")}
									</Col>
									<Col md='5'>
										{this.form.getInput("confirmation_mot_de_passe")}
									</Col>
								</Row>
								
								<Row className="form-group">
									<Col md="5">
										<div>{this.form.getLabel("phone_mobile")}</div>
									</Col>
									<Col md="7">{this.form.getInput("phone_mobile")}</Col>
								</Row>

								{this.props.hasOfficeNumber ? this.row(this.form, "phone_pro"): null}

								<Row className="mt-5">

									<div onClick={this.validateToScrollTop} className="mx-auto form-buttons">
										{this.form.submitButton(submitButtoni18nId, { className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green" })}
									</div>

								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>

			</React.Fragment>
		);
	};
};


const mapDispatchToProps = dispatch => {
	return {
		onEmailExists: (email, cbk) => emailExists(email, cbk)
	};
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(UserForm);